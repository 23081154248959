import * as React from "react"
import Layout from "../components/layout/en"
import { StaticImage } from "gatsby-plugin-image"


// markup
const ServiciosPage = (props) => {

  return (
    <Layout location={props.location} pageId="services" metaTitle="Services | Crequs">
      <section className="service_v1_page">
        <div className="container">
          <div className="row service_v1_content">
            <div className="col-lg-6">
              <div className="sv-title">
                <h2>We create tailormade digital products and experiences.</h2>
                <p>We are experts in Drupal, WordPress, React and webpage optimisation.</p>
              </div>
              <div className="services_items">
                <div className="service-item">
                  <div className="service-thumb">
                      <StaticImage
                          src="../images/illustracion/about-js.svg"
                          alt="Crequs"
                          width={500}
                          loading="eager"
                          backgroundColor="transparent"
                          placeholder="tracedSVG"
                      />
                  </div>
                  <div className="service-info">
                    <h3>Javascript</h3>
                    <p>We love the agility and freshness brought by Javascript’s new frameworks.</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sk-col">
                          <h4>Frameworks</h4>
                          <ul>
                            <li><span>React</span></li>
                            <li><span>GatsbyJS</span></li>
                            <li><span>NextJS</span></li>
                            <li><span>GraphQL</span></li>
                            <li><span>jQuery</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sk-col pol">
                          <h4 className="top">What we do</h4>
                          <ul>
                            <li><span>Tailormade layout, landing pages, customised forms, data visualisation, web app, integration with WordPress and Drupal, Headless CMS, Jamstack, static websites</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-item">
                  <div className="service-thumb">
                    <StaticImage
                        src="../images/illustracion/about-seo.svg"
                        alt="Crequs"
                        width={500}
                        loading="eager"
                        backgroundColor="transparent"
                        placeholder="tracedSVG"
                    />
                  </div>
                  <div className="service-info">
                    <h3>Marketing and SEO</h3>
                    <p>We collaborate with numerous marketing agencies, offering services for the technical implementation of marketing strategies.</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sk-col">
                          <h4>Stacks</h4>
                          <ul>
                            <li><span>Google: Tag Manager, Analytics, Optimize</span></li>
                            <li><span>Adforms</span></li>
                            <li><span>Facebook API</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sk-col pol">
                          <h4 className="top">What we implement</h4>
                          <ul>
                            <li><span>Measurement plans, script integration, privacy policy and cookie configuration, consultations, on-site SEO optimisation, a/b testing.</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="services_items style2">
                <div className="service-item ml-auto">
                  <div className="service-thumb">
                                      <StaticImage
                                          src="../images/illustracion/about-php.svg"
                                          alt="Crequs"
                                          width={500}
                                          loading="eager"
                                          backgroundColor="transparent"
                                          placeholder="tracedSVG"
                                      />
                  </div>
                  <div className="service-info">
                    <h3>PHP</h3>
                    <p>We specialise in custom development using the best content managers on the market.</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sk-col">
                          <h4>Content Managers</h4>
                          <ul>
                            <li><span>Wordpress</span></li>
                            <li><span>Drupal</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sk-col pol">
                          <h4 className="top">What we offe</h4>
                          <ul>
                            <li><span>Load speed optimisation, landing pages, tailormade themes and modules, customised layout, e-commerce, integration with CRM, Rest API, on-page SEO optimisation.</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-item ml-auto">
                  <div className="service-thumb">
                                      <StaticImage
                                          src="../images/illustracion/about-api.svg"
                                          alt="Crequs"
                                          width={500}
                                          loading="eager"
                                          backgroundColor="transparent"
                                          placeholder="tracedSVG"
                                      />
                  </div>
                  <div className="service-info">
                    <h3>API</h3>
                    <p>We integrate numerous tools and we’ve worked with dozens of APIs.</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sk-col">
                          <h4>Platforms</h4>
                          <ul>
                            <li><span>CRM: Zoho, Salesforce</span></li>
                            <li><span>Active Campaign</span></li>
                            <li><span>Mailchimp</span></li>
                            <li><span>Payments: Stripe, Redsys</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sk-col pol">
                          <h4 className="top">How we can help you</h4>
                          <ul>
                            <li><span>Advanced integration, recurring payments, consultations, data visualisation, web scraping.</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ServiciosPage


